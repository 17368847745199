// import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Button from "src/common/components/Button";
// import { AppDispatch, RootState } from "src/state/store";
// import { verifyOtp } from "../controllers/Auth.Controller";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { TbLoader } from "react-icons/tb";
import InputField from "src/common/components/InputField";
import { apiClient } from "src/lib/utils";

const VerifyEmailOTP = () => {
  const { email } = useParams();
  const navigate = useNavigate();

  // const dispatch: AppDispatch = useDispatch();
  // const { isLoading, error, success, result, verificationStatus } = useSelector(
  //   (state: RootState) => state.auth
  // );

  const initialFormValues = {
    otp: "",
  };

  const [formData, setFormData] = useState(initialFormValues);
  const [errors, setErrors] = useState(initialFormValues);
  const [timeLeftInSeconds, setTimeLeftInSeconds] = useState(60);
  // const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [resendingOTP, setResendingOTP] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | string) => {
    if (typeof e === "string") {
      setFormData((prev) => ({ ...prev, otp: e }));
      setErrors((prev) => ({ ...prev, otp: "" }));
    } else {
      const { name, value } = e.target;
      setFormData((prev) => ({ ...prev, [name]: value }));
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleSubmit = async (e: any) => {
    if (loading) return;

    e.preventDefault();

    try {
      if (formData.otp) {
        setLoading(true);

        const response = await apiClient.get(`/auth/verify/${formData.otp}`);
        if (response.data.success) {
          localStorage.setItem(
            "credentials",
            JSON.stringify(response.data.data)
          );
          navigate("/new-password");
        }
      }
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ?? "OOPS!! Something went wrong!"
      );
    }
    setLoading(false);
  };

  // useEffect(() => {
  //   // Handle verification status
  //   if (verificationStatus === "succeeded") {
  //     // alert("sdfsd");
  //     navigate("/new-password");
  //   } else if (verificationStatus === "failed") {
  //     console.error(error);
  //   }

  //   if (success && result) {
  //     toast.success("Verification successful");
  //   }

  //   if (error) {
  //     toast.error(error, {
  //       className: "first-letter:capitalize",
  //     });
  //   }
  // }, [verificationStatus, success, result, error, navigate]);

  useEffect(() => {
    if (timeLeftInSeconds > 0) {
      const timer = setInterval(() => {
        setTimeLeftInSeconds((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else {
      // setIsButtonDisabled(false);
    }
  }, [timeLeftInSeconds]);

  useEffect(() => {
    if (!email) {
      navigate("/sign-in");
    }
  }, []);

  const handleResendOTP = async () => {
    try {
      if (resendingOTP) return;
      if (loading) return;
      setResendingOTP(true);
      const response = await apiClient.post(`/auth/send-verification`, {
        email,
      });
      if (response.data.success) {
        toast.success("Verification OTP sent");
        setTimeLeftInSeconds(60);
      }
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ?? "OOPS!! Something went wrong!"
      );
    }
    setResendingOTP(false);
  };

  return (
    <div className="size-full">
      <div className="flex flex-col items-center size-full max-w-[602px] mx-auto">
        <div className="flex flex-col gap-4 text-center">
          <h4 className="!text-[32px]">VERIFY EMAIL</h4>
          <p className="text-base md:text-lg font-normal opacity-60">
            We’ve sent an OTP to <b>{email}</b>. Please check your inbox and
            click the link to verify your email.
          </p>
        </div>

        <div className="w-full my-8">
          <form
            onSubmit={handleSubmit}
            className="w-full flex flex-col gap-3 md:gap-6"
          >
            {/* OTP Field */}
            <InputField
              name="otp"
              placeholder="Enter OTP"
              value={formData.otp}
              onChange={handleChange}
              error={errors.otp}
              inputType="otp"
              disabled={loading}
              showLabel={false}
            />

            <Button
              type="submit"
              variant={"secondary"}
              size={"lg"}
              disabled={loading}
              className="!w-full mt-4"
            >
              {loading ? (
                <>
                  <TbLoader size={22} className="mr-1.5 animate-spin" /> PLEASE
                  WAIT...
                </>
              ) : (
                "VERIFY OTP"
              )}
            </Button>
            <p className="text-center">Didn't receive an email?</p>
          </form>

          <Button
            onClick={handleResendOTP}
            variant={"outline"}
            size={"lg"}
            disabled={loading || timeLeftInSeconds > 0}
            className="!w-full mt-4"
          >
            {resendingOTP ? (
              <>
                <TbLoader size={22} className="mr-1.5 animate-spin" /> PLEASE
                WAIT...
              </>
            ) : timeLeftInSeconds > 0 ? (
              `Resend in ${timeLeftInSeconds}`
            ) : (
              "RESEND OTP"
            )}
          </Button>
        </div>

        <div className="w-full mt-auto">
          <div className="bg-secondary h-4 rounded-full w-max gap-1 flex items-center p-1">
            <span className="h-2 w-6 rounded-full bg-white" />
            <span className="size-2 rounded-full bg-white" />
            <span className="size-2 rounded-full bg-white" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmailOTP;
