import { useState } from "react";
import Button from "src/common/components/Button";
import { IoMailOutline } from "react-icons/io5";
import { FormEvent } from "react";
import InputField from "src/common/components/InputField";
// import { handleResendVerification } from "../controllers/Auth.Controller";
// import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { AppDispatch, RootState } from "src/state/store";
import toast from "react-hot-toast";
import { TbLoader } from "react-icons/tb";
import { apiClient } from "src/lib/utils";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  // const dispatch: AppDispatch = useDispatch();
  // const { isLoading, error, success, result } = useSelector(
  //   (state: RootState) => state.auth
  // );

  const initialFormValues = {
    email: "",
  };

  const [formData, setFormData] = useState(initialFormValues);
  const [errors, setErrors] = useState(initialFormValues);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const validateInputs = async (formData: any) => {
    const rules = {
      email: [
        { check: (value: string) => !!value, message: "Email is required" },
        {
          check: (value: string) => /\S+@\S+\.\S+/.test(value),
          message: "Invalid email address",
        },
      ],
    };

    let hasError = false;
    const newErrors: Partial<IRegisterData> = {};

    Object.keys(rules).forEach((field) => {
      const value = formData[field as keyof IRegisterData];
      const fieldRules = rules[field as keyof typeof rules];

      for (const rule of fieldRules) {
        if (!rule.check(value)) {
          newErrors[field as keyof IRegisterData] = rule.message;
          hasError = true;
          break; // Exit loop on first validation error
        }
      }
    });

    setErrors(newErrors as any);
    return hasError;
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    if (loading) return;
    e.preventDefault();

    try {
      const hasError = await validateInputs(formData);

      if (!hasError) {
        setLoading(true);
        const response = await apiClient.post("/auth/send-verification", {
          email: formData.email,
        });
        if (response.data.success) {
          toast.success("Verification OTP sent");
          navigate(`/verify-email/${formData.email}`);
        } else {
          toast.error(response.data.message ?? "OOPS!! Something went wrong!");
        }
      }
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ?? "OOPS!! Something went wrong!"
      );
    }
    setLoading(false);
  };

  return (
    <div className="size-full">
      <div className="flex flex-col items-center size-full max-w-[602px] mx-auto">
        <div className="flex flex-col gap-4 text-center">
          <h4 className="!text-[32px]">FORGOT PASSWORD?</h4>
          <p className="text-base md:text-lg font-normal opacity-60">
            Just enter the email address you signed up with, and we'll send you
            a code to reset your password.
          </p>
        </div>

        <div className="w-full mt-6 mb-8">
          <form
            onSubmit={handleSubmit}
            className="w-full flex flex-col gap-3 md:gap-6"
          >
            {/* Email Field */}
            <InputField
              name="email"
              type="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              error={errors.email}
              icon={IoMailOutline}
              disabled={loading}
            />

            <Button
              type="submit"
              variant={"secondary"}
              size={"lg"}
              className="!w-full mt-8"
              disabled={loading}
            >
              {loading ? (
                <>
                  <TbLoader size={22} className="mr-1.5 animate-spin" /> PLEASE
                  WAIT...
                </>
              ) : (
                "REQUEST PASSWORD RESET"
              )}
            </Button>
          </form>
        </div>

        <div className="w-full mt-auto">
          <div className="bg-secondary h-4 rounded-full w-max gap-1 flex items-center p-1">
            <span className="h-2 w-6 rounded-full bg-white" />
            <span className="size-2 rounded-full bg-white" />
            <span className="size-2 rounded-full bg-white" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
