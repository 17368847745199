import { useEffect } from "react";
import toast from "react-hot-toast";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import Assets from "src/assets";
import Button from "src/common/components/Button";
import Footer from "src/common/components/Footer";
import Header from "src/common/components/Header";
import MaxWrapper from "src/common/components/MaxWrapper";
import { apiClient, groupMatchesByDate } from "src/lib/utils";
import {
  bulkSetMatches,
  setLoadingState,
  setRounds,
} from "src/state/slices/appSlice";
import { useAppDispatch, useAppSelector } from "src/state/store";

export default function DashboardLayout() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const matches = useAppSelector((state) => state.app.matches);
  useEffect(() => {
    (async function () {
      try {
        const credentials = localStorage.getItem("credentials");
        if (!credentials) {
          navigate("/");
        } else {
          if (matches.length) return;
          dispatch(setLoadingState(true));
          const response = await apiClient.get("/match", {
            params: {
              page: 0,
              size: 100,
            },
            headers: {
              Authorization: `bearer ${JSON.parse(credentials)?.accessToken}`,
            },
          });
          if (response?.data?.success) {
            const groupedMatches = groupMatchesByDate(
              response.data.data?.matches?.rows
            );
            dispatch(
              setRounds([
                response.data.data.total_rounds,
                response.data.data.current_round,
              ])
            );
            dispatch(bulkSetMatches(groupedMatches));
          }
        }
      } catch (error) {
        toast.error("OOPPSS!! Something went wrong");
      }
      dispatch(setLoadingState(false));
    })();
  }, []);

  return (
    <div className="w-full antialiased flex flex-col flex-1 relative bg-background/60">
      <div className="-z-10">
        <img
          src={Assets.grass}
          alt="grass"
          className="fixed top-0 left-0 size-full object-cover"
        />
      </div>
      <Header />
      <MaxWrapper className="py-4 flex-1">
        <div className="flex flex-col">
          <div className="rounded-lg rounded-b-none bg-background p-2 flex flex-col">
            <div className="w-full flex items-center flex-wrap justify-end gap-3 relative px-2 md:px-8 py-4 md:py-14 overflow-hidden rounded-md">
              <img
                className="absolute top-0 left-0 w-full h-full object-cover"
                alt=""
                src={Assets.dashboardGrass}
              />
              <NavLink
                to="/profile"
                children={({ isActive }) => (
                  <Button
                    className={`!w-max !px-4 relative !text-sm md:!text-base ${
                      !isActive ? "!bg-background !text-white" : ""
                    }`}
                  >
                    PROFILE
                  </Button>
                )}
              />
              <NavLink
                to="/leaderboard"
                children={({ isActive }) => (
                  <Button
                    className={`!w-max !px-4 relative !text-sm md:!text-base ${
                      !isActive ? "!bg-background !text-white" : ""
                    }`}
                  >
                    LEADERBOARD
                  </Button>
                )}
              />
              <NavLink
                to="/upcoming-matches"
                children={({ isActive }) => (
                  <Button
                    className={`!w-max !px-4 relative !text-sm md:!text-base ${
                      !isActive ? "!bg-background !text-white" : ""
                    }`}
                  >
                    UPCOMING MATCHES
                  </Button>
                )}
              />
            </div>

            <Outlet />
          </div>
        </div>
      </MaxWrapper>
      <Footer />
    </div>
  );
}
