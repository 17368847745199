import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  handleResendVerification,
  handleSignIn,
  handleSignUp,
  verifyOtp,
} from "src/pages/auth/controllers/Auth.Controller";

const initialState: IAuthState = {
  isLoading: false,
  error: null,
  success: false,
  result: null,
  verificationStatus: "idle",
  redirectionPath: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setRedirectionPath(state, action: PayloadAction<string>) {
      state.redirectionPath = action.payload;
    },

    clearAuth: (state) => {
      state.isLoading = false;
      state.error = null;
      state.success = false;
      state.result = null;
      state.verificationStatus = "idle";
      state.redirectionPath = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(handleSignUp.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.success = false;
        state.result = null;
      })
      .addCase(
        handleSignUp.fulfilled,
        (state, action: PayloadAction<IAuthResponse>) => {
          state.isLoading = false;
          state.success = true;
          state.result = action.payload;
        }
      )
      .addCase(handleSignUp.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
        state.result = null;
      })
      .addCase(handleSignIn.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.success = false;
        state.result = null;
      })
      .addCase(
        handleSignIn.fulfilled,
        (state, action: PayloadAction<IAuthResponse>) => {
          state.isLoading = false;
          state.success = true;
          state.result = action.payload;
        }
      )
      .addCase(handleSignIn.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
        state.result = null;
      })
      .addCase(handleResendVerification.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.success = false;
        state.result = null;
        state.verificationStatus = "loading";
      })
      .addCase(
        handleResendVerification.fulfilled,
        (state, action: PayloadAction<IAuthResponse>) => {
          state.isLoading = false;
          state.success = true;
          state.result = action.payload;
          state.verificationStatus = "succeeded";
        }
      )
      .addCase(handleResendVerification.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
        state.result = null;
        state.verificationStatus = "failed";
      })
      .addCase(verifyOtp.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.success = false;
        state.result = null;
        state.verificationStatus = "loading";
      })
      .addCase(
        verifyOtp.fulfilled,
        (state, action: PayloadAction<IAuthResponse>) => {
          state.isLoading = false;
          state.success = true;
          state.result = action.payload;
          state.verificationStatus = "succeeded";
        }
      )
      .addCase(verifyOtp.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
        state.result = null;
        state.verificationStatus = "failed";
      });
  },
});

export const { clearAuth } = authSlice.actions;

export default authSlice.reducer;
