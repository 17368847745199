import { Outlet } from "react-router-dom";
import Assets from "src/assets";

export default function AuthLayout() {
  return (
    <div className="min-h-dvh w-full antialiased flex flex-1 relative p-4 gap-5">
      <div className="md:px-4 py-6 flex w-full lg:w-[45%]">
        <Outlet />
      </div>

      <div className="hidden lg:flex flex-1 w-[55%] lg:w-[45%] flex-col justify-between bg-background/60 relative overflow-clip p-6">
        <div className="-z-10 absolute top-0 left-0 size-full">
          <img
            src={Assets.grass}
            alt="grass"
            className="size-full object-cover"
          />
        </div>
        <img
          src={Assets.logo}
          alt="logo"
          height={102}
          width={538}
          className="ml-auto"
        />

        <div className="flex flex-col gap-5">
          <h3>Analyze.</h3>
          <h3>Predict.</h3>
          <h3>Win.</h3>
        </div>
      </div>
    </div>
  );
}
