import { useEffect, useRef, useState } from "react";
import { TbLoader } from "react-icons/tb";
import { useLocation } from "react-router-dom";
import Assets from "src/assets";
import { LeaderboardProp } from "src/state/slices/appSlice";
import { useAppSelector } from "src/state/store";
// import DateRangePicker from "react-daterange-picker";
// import "react-daterange-picker/dist/css/react-calendar.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import toast from "react-hot-toast";
import { apiClient, IMAGE_ENDPOINT, IMAGE_PATH } from "src/lib/utils";
import { IoCaretDown } from "react-icons/io5";
export default function LeaderBoardContainer() {
  const location = useLocation();
  const { leaderboard: data, loading_state } = useAppSelector(
    (state) => state.app
  );

  const [should_trim, set_should_trim] = useState(false);

  useEffect(() => {
    if (location.pathname !== "/") {
      set_should_trim(false);
    } else {
      set_should_trim(true);
    }
  }, [location]);

  const [showPicker, setShowPicker] = useState(false); // Track if the picker is visible
  const [dateVaue, setDateValue] = useState<Date[] | null>(null);

  const handleDateChange = (value: Date[]) => {
    setDateValue(value); // Set the selected date range
    setShowPicker(false); // Close the picker after selection
  };

  const { total_rounds, current_round } = useAppSelector((state) => state.app);
  // const [activeRounds, setActiveRounds] = useState(current_round);

  // useEffect(() => {
  //   setActiveRounds(current_round);
  // }, [current_round]);

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);

      setShowPicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 640);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 640);
    };

    // Add event listener on mount
    window.addEventListener("resize", handleResize);

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [filter, setFilter] = useState(false);

  const [filter_result, set_filter_result] = useState<LeaderboardProp[]>([]);
  const [activeRounds, setActiveRounds] = useState<string | null>(null);
  const [startDate, setStartDate] = useState<Date | undefined>(new Date());
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const onChange = async (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

    if (end && start) {
      setShowPicker(false);

      await get_filter({
        round: activeRounds,
        range: {
          start: start,
          end: end,
        },
      });
    }
  };

  const [user, setUser] = useState<any>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const get_filter = async (params: any) => {
    try {
      if (loading) return;
      setFilter(true);
      setLoading(true);
      const response = await apiClient.get("/score", {
        params,
      });
      if (response.data.success) {
        if (Array.isArray(response.data.data.results)) {
          set_filter_result(response.data.data.results);
        } else {
          set_filter_result([]);
        }
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);

      toast.error(
        error.response?.data?.message || error.message || "An error occurred"
      );
    }
  };

  useEffect(() => {
    const credentials = localStorage.getItem("credentials");
    if (credentials) {
      setUser(JSON.parse(credentials));
    }
  }, []);

  return loading_state ? (
    <div className="flex items-center justify-center w-full">
      <TbLoader size={42} className="mr-1.5 animate-spin" />
    </div>
  ) : (
    <div className="w-full">
      <div className="w-full flex flex-col gap-8">
        <div className="flex flex-col gap-4 w-full">
          <div className="p-4 h-[60px] relative">
            <div className="size-full absolute top-0 left-0 bg-primary-foreground opacity-80">
              <img
                src={Assets.rough}
                alt=""
                className="size-full object-cover"
              />
            </div>

            <div className="relative flex items-center">
              <div className="flex items-center gap-6 md:ml-28">
                <div
                  className="flex items-start gap-6 relative "
                  ref={dropdownRef}
                >
                  <h5>LEADERBOARD</h5>
                  {showPicker && (
                    <div className="z-50 absolute left-0">
                      <DatePicker
                        selected={startDate}
                        onChange={onChange}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        inline
                      />
                    </div>
                  )}
                  <div className="flex flex-col items-center justify-center">
                    <button onClick={() => setShowPicker(true)}>Range</button>
                    {startDate && endDate ? (
                      <p className=" text-[9px] lg:text-xs  font-bold">
                        {startDate
                          .toLocaleDateString("en-GB", {
                            day: "numeric",
                            month: isMobile ? "numeric" : "short",
                          })
                          .toString() +
                          " - " +
                          endDate
                            .toLocaleDateString("en-GB", {
                              day: "numeric",
                              month: isMobile ? "numeric" : "short",
                            })
                            .toString()}
                      </p>
                    ) : null}
                  </div>
                  <div className="flex flex-col items-center">
                    <button onClick={toggleDropdown}>
                      Round
                      {/* <IoCaretDown className="size-4 ml-2" /> */}
                    </button>
                    {activeRounds ? (
                      <p className=" text-[9px] lg:text-xs  font-bold">
                        {activeRounds}
                      </p>
                    ) : null}
                  </div>

                  {filter ? (
                    <button
                      onClick={() => {
                        set_filter_result([]);
                        setFilter(false);
                        setActiveRounds(null);
                        setEndDate(undefined);
                      }}
                    >
                      Clear
                    </button>
                  ) : null}

                  {isOpen && (
                    <div className="absolute z-50 right-0  h-60 overflow-y-auto w-48 mt-2 bg-white border border-gray-200 rounded-lg shadow-lg">
                      {Array.from({ length: total_rounds }).map((_, index) => {
                        return (
                          <button
                            key={index}
                            onClick={() => {
                              get_filter({
                                round: index + 1,
                                range: {
                                  start: startDate?.toISOString(),
                                  end: endDate?.toISOString(),
                                },
                              });
                              setIsOpen(false);
                              setActiveRounds((index + 1).toString());
                            }}
                            className="block px-4 py-2 text-gray-800 hover:bg-gray-100 w-full text-left font-light"
                          >
                            Round {index + 1}
                          </button>
                        );
                      })}
                    </div>
                  )}
                </div>
                {/* <button></button> */}
                {/* <h6>Year</h6> */}
                {/* <h6>Lifetime</h6> */}
              </div>
            </div>
          </div>

          {loading ? (
            <div className="flex items-center justify-center w-full">
              <TbLoader size={32} className="mr-1.5 animate-spin" />
            </div>
          ) : (
            <div className="flex flex-col lg:flex-row gap-6">
              <div className="mx-auto sm:max-w-[400px] w-full hidden sm:flex flex-col gap-2">
                <div className="border-2 p-2 rounded-lg">
                  <div className="w-full aspect-[0.85]">
                    <img
                      src={
                        user
                          ? user.profile_picture
                            ? `${IMAGE_ENDPOINT}/${IMAGE_PATH}/${user.profile_picture}`
                            : Assets.dummyAvatar
                          : (filter ? filter_result : data).length
                          ? (filter ? filter_result : data)[0]?.user
                              ?.profile_picture
                            ? `${IMAGE_ENDPOINT}/${IMAGE_PATH}/${
                                (filter ? filter_result : data)[0].user
                                  ?.profile_picture
                              }`
                            : Assets.dummyAvatar
                          : Assets.dummyAvatar
                      }
                      alt=""
                      onError={(e) => {
                        (e.target as HTMLImageElement).onerror = null; // Prevent infinite loop
                        (e.target as HTMLImageElement).src = Assets.dummyAvatar;
                      }}
                      loading="lazy"
                      className="size-full rounded-md object-cover"
                    />
                  </div>
                </div>

                <div className="flex flex-col gap-2 w-full">
                  <div className="flex items-center justify-between bg-secondary py-5 px-7">
                    <h3>
                      {user
                        ? user.username
                        : (filter ? filter_result : data).length
                        ? (filter ? filter_result : data)[0]?.user?.username
                        : ""}
                    </h3>
                    <h3 className="text-primary-foreground">
                      {user
                        ? data.findIndex((fd) => fd.user.id === user.id) + 1
                        : "1"}
                    </h3>
                  </div>
                  <div className="flex items-center justify-between bg-secondary py-3 px-7">
                    <h5>Total Points</h5>
                    <h5>
                      {user
                        ? Number(
                            data.find((fd) => fd.user.id === user.id)
                              ?.totalPoints ?? 0
                          ).toFixed(1)
                        : Number(
                            (filter ? filter_result : data).length
                              ? (filter ? filter_result : data)[0]?.totalPoints
                              : 0
                          )?.toFixed(1)}
                    </h5>
                  </div>
                </div>
              </div>

              <div className="flex-1 flex flex-col gap-3">
                <div className="bg-foreground text-primary-foreground h-[42px] flex items-center">
                  <div className="flex items-center justify-center w-[60px] sm:w-20 h-full">
                    <h6>POSITION</h6>
                  </div>
                  <div className="flex items-center pl-16 flex-1">
                    <h6>PLAYER</h6>
                  </div>
                  <div className="flex items-center px-4 w-20 sm:w-[200px] h-full">
                    <h6>TOTAL</h6>
                  </div>
                </div>

                <div className="flex-col sm:h-[calc(100vh-60px)] w-full sm:overflow-y-auto">
                  <div className="flex flex-col gap-2 w-full">
                    {(should_trim
                      ? (filter ? filter_result : data).slice(0, 10)
                      : filter
                      ? filter_result
                      : data
                    ).map((_data: LeaderboardProp, _key: number) => {
                      const credentials = localStorage.getItem("credentials");

                      const userId = credentials
                        ? JSON.parse(credentials)
                        : null;

                      return (
                        <div
                          key={_key}
                          className={`h-[48px] flex items-center border-2 ${
                            userId && userId.id === _data.user.id
                              ? "border-green-400"
                              : ""
                          }`}
                        >
                          <div className="flex items-center justify-center w-[60px] sm:w-20 h-full bg-secondary text-primary-foreground">
                            <h6>{_key + 1}</h6>
                          </div>
                          <div className="flex items-center gap-4 pl-4 flex-1">
                            <div className="size-8 rounded-full">
                              <img
                                src={
                                  _data.user.profile_picture
                                    ? `${IMAGE_ENDPOINT}/${IMAGE_PATH}/${_data.user.profile_picture}`
                                    : Assets.dummyAvatar
                                }
                                alt=""
                                onError={(e) => {
                                  (e.target as HTMLImageElement).onerror = null; // Prevent infinite loop
                                  (e.target as HTMLImageElement).src =
                                    Assets.dummyAvatar;
                                }}
                                loading="lazy"
                                className="size-full rounded-full object-cover"
                              />
                            </div>
                            <h6>{_data.user.username}</h6>
                          </div>
                          <div className="flex items-center px-4 w-20 sm:w-[200px] h-full">
                            <h6>{Number(_data.totalPoints).toFixed(1)}</h6>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="border rounded-md py-16 px-4  sm:flex items-center justify-center flex-col text-center gap-5">
          <h3>SCORING SYSTEM</h3>
          <p className="text-base md:text-lg">
            Here is how points are awarded on 27Prediction League
          </p>

          <div className="flex items-start flex-col sm:flex-row justify-between max-w-[950px] w-full gap-5 md:gap-10 mt-4">
            <div className="flex flex-col gap-2 bg-secondary p-8 w-full">
              <h4 className="text-[#28A267]">
                5 <small>PTS</small>
              </h4>

              <p className="text-sm text-[#ECF9F0]">
                Correct prediction of the exact result
              </p>
            </div>
            <div className="flex flex-col gap-2 bg-secondary p-8 w-full">
              <h4 className="text-[#28A267]">
                3 <small>PTS</small>
              </h4>

              <p className="text-sm text-[#ECF9F0]">
                Correct prediction of the match winner and goal range (0-2 or
                3+)
              </p>
            </div>
            <div className="flex flex-col gap-2 bg-secondary p-8 w-full">
              <h4 className="text-[#28A267]">
                2 <small>PTS</small>
              </h4>

              <p className="text-sm text-[#ECF9F0]">
                Correct prediction of only the match winner
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
