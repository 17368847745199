import { useState } from "react";
import Button from "src/common/components/Button";
import { GoEye, GoEyeClosed } from "react-icons/go";
import { FormEvent } from "react";
import { MdCheck } from "react-icons/md";
import { apiClient, cn } from "src/lib/utils";
import InputField from "src/common/components/InputField";
// import { setNewPassword } from "../controllers/Auth.Controller";
// import { useDispatch, useSelector } from "react-redux";
// import { AppDispatch, RootState } from "src/state/store";
import { TbLoader } from "react-icons/tb";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

interface IResetPassword {
  password: string;
  confirmPassword: string;
}

const SetNewPassword = () => {
  const navigate = useNavigate();

  const initialFormValues = {
    password: "",
    confirmPassword: "",
  };

  // const dispatch: AppDispatch = useDispatch();
  // const { isLoading, error, success, result } = useSelector(
  //   (state: RootState) => state.auth
  // );
  const [loading, setLoading] = useState<boolean>(false);

  const [formData, setFormData] = useState(initialFormValues);
  const [errors, setErrors] = useState(initialFormValues);
  const [isPasswordValid, setIsPasswordValid] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
  });
  const [isPasswordMatching, setIsPasswordMatching] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const validatePassword = (password: string) => {
    setIsPasswordValid({
      length: password.length >= 6,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /[0-9]/.test(password),
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" }));

    if (name === "password" || name === "confirmPassword") {
      validatePassword(value);

      if (name === "confirmPassword") {
        setIsPasswordMatching(value === formData.password && value !== "");
      }
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "password") {
      validatePassword(value);
    }
    if (name === "confirmPassword") {
      setIsPasswordMatching(value === formData.password && value !== "");
    }
  };

  const validateInputs = async (formData: IResetPassword) => {
    const rules = {
      password: [
        {
          check: (value: string) => !!value,
          message: "Password is required",
        },
        {
          check: (value: string) => value.length >= 6,
          message: "Password must be at least 6 characters long",
        },
        {
          check: (value: string) => /[A-Z]/.test(value),
          message: "Password must contain an uppercase letter",
        },
        {
          check: (value: string) => /[a-z]/.test(value),
          message: "Password must contain a lowercase letter",
        },
        {
          check: (value: string) => /[0-9]/.test(value),
          message: "Password must contain a number",
        },
      ],
      confirmPassword: [
        {
          check: (value: string) => !!value,
          message: "Please confirm your password",
        },
        {
          check: (value: string) => value === formData.password,
          message: "Passwords do not match",
        },
      ],
    };

    let hasError = false;
    const newErrors: Partial<IResetPassword> = {};

    Object.keys(rules).forEach((field) => {
      const value = formData[field as keyof IResetPassword];
      const fieldRules = rules[field as keyof typeof rules];

      for (const rule of fieldRules) {
        if (!rule.check(value)) {
          newErrors[field as keyof IResetPassword] = rule.message;
          hasError = true;
          break; // Exit loop on first validation error
        }
      }
    });

    setErrors(newErrors as any);
    return hasError;
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    try {
      const credentials = localStorage.getItem("credentials");

      if (loading) return;

      e.preventDefault();

      const hasError = await validateInputs(formData);

      if (!hasError) {
        setLoading(true);

        const response = await apiClient.post(
          `/auth/reset-password`,
          {
            newPassword: formData.confirmPassword,
          },
          {
            headers: {
              Authorization: `bearer ${
                credentials ? JSON.parse(credentials).accessToken : ""
              }`,
            },
          }
        );
        if (response.data.success) {
          toast.success("Password reset successfully");
          navigate("/sign-in");
        }
        // await dispatch(setNewPassword({ newPassword: formData.confirmPassword }));
      }
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ?? "OOPS!! Something went wrong!"
      );
    }
    setLoading(false);
  };

  // useEffect(() => {
  //   if (success && result) {
  //     navigate("/reset-password");
  //   }

  //   if (error) {
  //     toast.error(error, {
  //       className: "first-letter:capitalize",
  //     });
  //   }
  // }, [success, result, navigate, error]);

  return (
    <div className="size-full">
      <div className="flex flex-col items-center size-full max-w-[602px] mx-auto">
        <div className="flex flex-col gap-4 text-center">
          <h4 className="!text-[32px]">SET NEW PASSWORD</h4>
          <p className="text-base md:text-lg font-normal opacity-60">
            Create a new password that’s easy for you to remember but hard for
            others to guess.
          </p>
        </div>

        <div className="w-full mt-6 mb-8">
          <form
            onSubmit={handleSubmit}
            className="w-full flex flex-col gap-3 md:gap-6"
          >
            {/* Password Fields */}
            <div
              key="password-group"
              className="flex flex-col sm:flex-row md:flex-col lg:flex-row gap-3"
            >
              <InputField
                name="password"
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={formData.password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.password}
                icon={showPassword ? GoEyeClosed : GoEye}
                onIconClick={() => setShowPassword((prev) => !prev)}
                disabled={loading}
              />
              <InputField
                name="confirmPassword"
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Confirm Password"
                value={formData.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.confirmPassword}
                icon={showConfirmPassword ? GoEyeClosed : GoEye}
                onIconClick={() => setShowConfirmPassword((prev) => !prev)}
                disabled={loading}
              />
            </div>

            {/* Password Strength Indicators */}
            <div className="flex items-start gap-2 flex-wrap">
              <div
                className={cn(
                  "border rounded-md py-1 px-2 flex items-center gap-2",
                  {
                    "border-primary-foreground text-primary-foreground":
                      isPasswordValid.length,
                    "opacity-50 cursor-not-allowed": loading,
                  }
                )}
              >
                <p className="text-sm">6 characters</p> <MdCheck size={16} />
              </div>
              <div
                className={cn(
                  "border rounded-md py-1 px-2 flex items-center gap-2",
                  {
                    "border-primary-foreground text-primary-foreground":
                      isPasswordValid.uppercase,
                    "opacity-50 cursor-not-allowed": loading,
                  }
                )}
              >
                <p className="text-sm">Uppercase</p> <MdCheck size={16} />
              </div>
              <div
                className={cn(
                  "border rounded-md py-1 px-2 flex items-center gap-2",
                  {
                    "border-primary-foreground text-primary-foreground":
                      isPasswordValid.lowercase,
                    "opacity-50 cursor-not-allowed": loading,
                  }
                )}
              >
                <p className="text-sm">Lowercase</p> <MdCheck size={16} />
              </div>
              <div
                className={cn(
                  "border rounded-md py-1 px-2 flex items-center gap-2",
                  {
                    "border-primary-foreground text-primary-foreground":
                      isPasswordValid.number,
                    "opacity-50 cursor-not-allowed": loading,
                  }
                )}
              >
                <p className="text-sm">Number</p> <MdCheck size={16} />
              </div>
              <div
                className={cn(
                  "border rounded-md py-1 px-2 flex items-center gap-2",
                  {
                    "border-primary-foreground text-primary-foreground":
                      isPasswordMatching,
                    "opacity-50 cursor-not-allowed": loading,
                  }
                )}
              >
                <p className="text-sm">Password Matching</p>{" "}
                <MdCheck size={16} />
              </div>
            </div>

            <Button
              type="submit"
              variant={"secondary"}
              size={"lg"}
              disabled={loading}
              className="!w-full mt-8"
            >
              {loading ? (
                <>
                  <TbLoader size={22} className="mr-1.5 animate-spin" /> PLEASE
                  WAIT...
                </>
              ) : (
                "RESET PASSWORD"
              )}
            </Button>
          </form>
        </div>

        <div className="w-full mt-auto">
          <div className="bg-secondary h-4 rounded-full w-max gap-1 flex items-center p-1">
            <span className="size-2 rounded-full bg-white" />
            <span className="h-2 w-6 rounded-full bg-white" />
            <span className="size-2 rounded-full bg-white" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetNewPassword;
