import { useEffect, useState } from "react";
import { IoMailOutline } from "react-icons/io5";
import { GoEye, GoEyeClosed } from "react-icons/go";
import { Link } from "react-router-dom";
import { FormEvent } from "react";
import { MdCheck } from "react-icons/md";
import { apiClient, cn } from "src/lib/utils";
import InputField from "src/common/components/InputField";
import Button from "src/common/components/Button";
// import { useDispatch, useSelector } from "react-redux";
// import { AppDispatch, RootState } from "src/state/store";
import { TbLoader } from "react-icons/tb";
// import { handleSignUp } from "../controllers/Auth.Controller";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const SignUp = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // const dispatch: AppDispatch = useDispatch();
  // const { isLoading, error, success, result } = useSelector(
  //   (state: RootState) => state.auth
  // );

  const initialFormValues: IRegisterData = {
    email: "",
    firstname: "",
    lastname: "",
    username: "",
    password: "",
    confirmPassword: "",
  };

  const [formData, setFormData] = useState(initialFormValues);
  const [errors, setErrors] = useState(initialFormValues);
  const [isPasswordValid, setIsPasswordValid] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
  });
  const [isPasswordMatching, setIsPasswordMatching] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const validatePassword = (password: string) => {
    setIsPasswordValid({
      length: password.length >= 6,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /[0-9]/.test(password),
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" }));

    if (name === "password" || name === "confirmPassword") {
      validatePassword(value);

      if (name === "confirmPassword") {
        setIsPasswordMatching(value === formData.password && value !== "");
      }
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "password") {
      validatePassword(value);
    }
    if (name === "confirmPassword") {
      setIsPasswordMatching(value === formData.password && value !== "");
    }
  };

  const validateInputs = async (formData: IRegisterData) => {
    const rules = {
      email: [
        { check: (value: string) => !!value, message: "Email is required" },
        {
          check: (value: string) => /\S+@\S+\.\S+/.test(value),
          message: "Invalid email address",
        },
      ],
      firstname: [
        {
          check: (value: string) => !!value,
          message: "First name is required",
        },
      ],
      lastname: [
        { check: (value: string) => !!value, message: "Last name is required" },
      ],
      username: [
        { check: (value: string) => !!value, message: "Username is required" },
      ],
      password: [
        { check: (value: string) => !!value, message: "Password is required" },
        {
          check: (value: string) => value.length >= 6,
          message: "Password must be at least 6 characters long",
        },
        {
          check: (value: string) => /[A-Z]/.test(value),
          message: "Password must contain an uppercase letter",
        },
        {
          check: (value: string) => /[a-z]/.test(value),
          message: "Password must contain a lowercase letter",
        },
        {
          check: (value: string) => /[0-9]/.test(value),
          message: "Password must contain a number",
        },
      ],
      confirmPassword: [
        {
          check: (value: string) => !!value,
          message: "Please confirm your password",
        },
        {
          check: (value: string) => value === formData.password,
          message: "Passwords do not match",
        },
      ],
    };

    let hasError = false;
    const newErrors: Partial<IRegisterData> = {};

    Object.keys(rules).forEach((field) => {
      const value = formData[field as keyof IRegisterData];
      const fieldRules = rules[field as keyof typeof rules];

      for (const rule of fieldRules) {
        if (!rule.check(value)) {
          newErrors[field as keyof IRegisterData] = rule.message;
          hasError = true;
          break; // Exit loop on first validation error
        }
      }
    });

    setErrors(newErrors as any);
    return hasError;
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    if (isLoading) return;

    e.preventDefault();

    try {
      const hasError = await validateInputs(formData);

      if (!hasError) {
        setIsLoading(true);
        const response = await apiClient.post("/auth/sign-up", formData);
        if (response.data.success) {
          toast.success("Verification OTP sent");
          navigate(`/verify-otp/${response.data.data?.email}`);
        } else {
          toast.error(
            response.data.message ?? response.data.error ?? "An error occured"
          );
        }

        //  await dispatch(handleSignUp(formData));
      }

      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);

      toast.error(
        error.response?.data?.message || error.message || "An error occurred"
      );
    }
  };

  // useEffect(() => {
  //   if (success && result) {
  //     toast.success("Verification OTP sent");
  //     navigate(`/verify-otp/${result.data?.email}`);
  //   }

  //   if (error) {
  //     toast.error(error, {
  //       className: "first-letter:capitalize",
  //     });
  //   }
  // }, [success, result, navigate, error]);

  return (
    <div className="size-full">
      <div className="flex flex-col items-center size-full max-w-[602px] mx-auto">
        <div className="flex flex-col gap-4 text-center">
          <h4 className="!text-[32px]">JOIN FANALYZE!</h4>
          <p className="text-base md:text-lg font-normal opacity-60">
            Sign up and start making winning predictions.
          </p>
        </div>

        <div className="w-full mt-6 mb-8">
          <form
            onSubmit={handleSubmit}
            className="w-full flex flex-col gap-3 md:gap-6"
          >
            {/* Email Field */}
            <InputField
              name="email"
              type="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.email}
              icon={IoMailOutline}
              disabled={isLoading}
            />

            {/* Name Fields */}
            <div
              key="name-group"
              className="flex flex-col sm:flex-col md:flex-row gap-3"
            >
              <InputField
                name="firstname"
                placeholder="First Name"
                value={formData.firstname}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.firstname}
                disabled={isLoading}
              />
              <InputField
                name="lastname"
                placeholder="Last Name"
                value={formData.lastname}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.lastname}
                disabled={isLoading}
              />
            </div>

            {/* Username Field */}
            <InputField
              name="username"
              placeholder="Username"
              value={formData.username}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.username}
              disabled={isLoading}
            />

            {/* Password Fields */}
            <div
              key="password-group"
              className="flex flex-col sm:flex-col md:flex-row gap-3"
            >
              <InputField
                name="password"
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={formData.password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.password}
                icon={showPassword ? GoEyeClosed : GoEye}
                onIconClick={() => setShowPassword((prev) => !prev)}
                disabled={isLoading}
              />
              <InputField
                name="confirmPassword"
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Confirm Password"
                value={formData.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.confirmPassword}
                icon={showConfirmPassword ? GoEyeClosed : GoEye}
                onIconClick={() => setShowConfirmPassword((prev) => !prev)}
                disabled={isLoading}
              />
            </div>

            {/* Password Strength Indicators */}
            <div className="flex items-start gap-2 flex-wrap">
              <div
                className={cn(
                  "border rounded-md py-1 px-2 flex items-center gap-2",
                  {
                    "border-primary-foreground text-primary-foreground":
                      isPasswordValid.length,
                    "opacity-50 cursor-not-allowed": isLoading,
                  }
                )}
              >
                <p className="text-sm">6 characters</p> <MdCheck size={16} />
              </div>
              <div
                className={cn(
                  "border rounded-md py-1 px-2 flex items-center gap-2",
                  {
                    "border-primary-foreground text-primary-foreground":
                      isPasswordValid.uppercase,
                    "opacity-50 cursor-not-allowed": isLoading,
                  }
                )}
              >
                <p className="text-sm">Uppercase</p> <MdCheck size={16} />
              </div>
              <div
                className={cn(
                  "border rounded-md py-1 px-2 flex items-center gap-2",
                  {
                    "border-primary-foreground text-primary-foreground":
                      isPasswordValid.lowercase,
                    "opacity-50 cursor-not-allowed": isLoading,
                  }
                )}
              >
                <p className="text-sm">Lowercase</p> <MdCheck size={16} />
              </div>
              <div
                className={cn(
                  "border rounded-md py-1 px-2 flex items-center gap-2",
                  {
                    "border-primary-foreground text-primary-foreground":
                      isPasswordValid.number,
                    "opacity-50 cursor-not-allowed": isLoading,
                  }
                )}
              >
                <p className="text-sm">Number</p> <MdCheck size={16} />
              </div>
              <div
                className={cn(
                  "border rounded-md py-1 px-2 flex items-center gap-2",
                  {
                    "border-primary-foreground text-primary-foreground":
                      isPasswordMatching,
                    "opacity-50 cursor-not-allowed": isLoading,
                  }
                )}
              >
                <p className="text-sm">Password Matching</p>{" "}
                <MdCheck size={16} />
              </div>
            </div>

            <Button
              type="submit"
              variant={"secondary"}
              size={"lg"}
              disabled={isLoading}
              className="!w-full mt-8"
            >
              {isLoading ? (
                <>
                  <TbLoader size={22} className="mr-1.5 animate-spin" /> PLEASE
                  WAIT...
                </>
              ) : (
                "CREATE ACCOUNT"
              )}
            </Button>
            <p className="text-center">
              Already have an account?{" "}
              <Link className="text-primary-foreground" to="/sign-in">
                Log In
              </Link>
            </p>
          </form>
        </div>

        <div className="w-full mt-auto">
          <div className="bg-secondary h-4 rounded-full w-max gap-1 flex items-center p-1">
            <span className="h-2 w-6 rounded-full bg-white" />
            <span className="size-2 rounded-full bg-white" />
            <span className="size-2 rounded-full bg-white" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
