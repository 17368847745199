import { Link } from "react-router-dom";
import Button from "src/common/components/Button";

const EmailVerified = () => {
  return (
    <div className="size-full">
      <div className="flex flex-col items-center size-full max-w-[602px] mx-auto">
        <div className="flex flex-col gap-4 text-center">
          <h4 className="!text-[32px]">EMAIL VERIFIED</h4>
          <p className="text-base md:text-lg font-normal opacity-60">
            Email successfully verified. Click below access dashboard.
          </p>
        </div>

        <div className="w-full mt-6 mb-8">
          <div className="w-full flex flex-col gap-3 md:gap-6">
            <Link to="/sign-in">
              <Button
                type="submit"
                variant={"secondary"}
                size={"lg"}
                className="!w-full mt-8">
                LOG IN ACCOUNT
              </Button>
            </Link>
          </div>
        </div>

        <div className="w-full mt-auto">
          <div className="bg-secondary h-4 rounded-full w-max gap-1 flex items-center p-1">
            <span className="h-2 w-6 rounded-full bg-white" />
            <span className="size-2 rounded-full bg-white" />
            <span className="size-2 rounded-full bg-white" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerified;
