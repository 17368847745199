import { useEffect, useState } from "react";
import Button from "src/common/components/Button";
import { IoMailOutline } from "react-icons/io5";
import { GoCheck, GoEye, GoEyeClosed } from "react-icons/go";
import { Link, useNavigate } from "react-router-dom";
import { FormEvent } from "react";
import InputField from "src/common/components/InputField";
import { TbLoader } from "react-icons/tb";
import toast from "react-hot-toast";
// import { AppDispatch, RootState } from "src/state/store";
// import { useDispatch, useSelector } from "react-redux";
// import { handleSignIn } from "../controllers/Auth.Controller";
import { useLocalStorage } from "src/common/controllers/localstorage";
import { apiClient } from "src/lib/utils";

const SignIn = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { setFn } = useLocalStorage();

  // const dispatch: AppDispatch = useDispatch();
  // const { isLoading, error, success, result } = useSelector(
  //   (state: RootState) => state.auth
  // );

  const initialFormValues = {
    email: "",
    password: "",
  };

  const [formData, setFormData] = useState(initialFormValues);
  const [errors, setErrors] = useState(initialFormValues);
  const [showPassword, setShowPassword] = useState(false);
  const [rememberDevice, setRememberDevice] = useState(true);

  const validateInputs = async (formData: ILoginData) => {
    const rules = {
      email: [
        { check: (value: string) => !!value, message: "Email is required" },
        {
          check: (value: string) => /\S+@\S+\.\S+/.test(value),
          message: "Invalid email address",
        },
      ],
      password: [
        {
          check: (value: string) => !!value,
          message: "Password is required",
        },
      ],
    };

    let hasError = false;
    const newErrors: Partial<ILoginData> = {};

    Object.keys(rules).forEach((field) => {
      const value = formData[field as keyof ILoginData];
      const fieldRules = rules[field as keyof typeof rules];

      for (const rule of fieldRules) {
        if (!rule.check(value)) {
          newErrors[field as keyof ILoginData] = rule.message;
          hasError = true;
          break;
        }
      }
    });

    setErrors(newErrors as any);
    return hasError;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    if (isLoading) return;
    e.preventDefault();
    try {
      const hasError = await validateInputs(formData);

      if (!hasError) {
        setIsLoading(true);
        const response = await apiClient.post("/auth/sign-in", formData);
        if (response.data.success) {
          setFn("credentials", response.data.data);
          toast.success("Successfully logged in");
          navigate("/upcoming-matches");
        } else {
          toast.error(
            response.data.message ?? response.data.error ?? "An error occured"
          );
        }
      }
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      if (error.response.data.message === "Email verification sent") {
        navigate(`/verify-otp/${formData.email}`);
        toast.success(
          error.response?.data?.message || error.message || "An error occurred"
        );
        return;
      }

      toast.error(
        error.response?.data?.message || error.message || "An error occurred"
      );
    }
  };

  // useEffect(() => {
  //   if (success && result) {
  //     setFn("credentials", result.data);
  //     toast.success("Successfully logged in");
  //     navigate("/profile");
  //   }

  //   if (error) {
  // toast.error(error, {
  //   className: "first-letter:capitalize",
  // });
  //   }
  // }, [success, result, navigate, error, rememberDevice, setFn, delFn]);

  return (
    <div className="size-full">
      <div className="flex flex-col items-center size-full max-w-[602px] mx-auto">
        <div className="flex flex-col gap-4 text-center">
          <h4 className="!text-[32px]">WELCOME BACK!</h4>
          <p className="text-base md:text-lg font-normal opacity-60">
            Log in to access your dashboard
          </p>
        </div>

        <div className="w-full mt-6 mb-8">
          <form
            onSubmit={handleSubmit}
            className="w-full flex flex-col gap-3 md:gap-6"
          >
            {/* Email Field */}
            <InputField
              name="email"
              type="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              error={errors.email}
              icon={IoMailOutline}
              disabled={isLoading}
            />

            {/* Password Field */}
            <InputField
              name="password"
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
              error={errors.password}
              icon={showPassword ? GoEyeClosed : GoEye}
              disabled={isLoading}
              onIconClick={() => setShowPassword((prev) => !prev)}
            />

            <div className="flex items-center justify-between gap-2 flex-wrap md:-mt-2">
              <div
                className="flex items-center gap-3 cursor-pointer select-none"
                onClick={() => setRememberDevice((prev) => !prev)}
              >
                <div className="size-5 rounded-sm border-2">
                  {rememberDevice && (
                    <GoCheck className="size-4 text-primary-foreground" />
                  )}
                </div>
                <p className="text-sm sm:text-base">Remember this device</p>
              </div>

              <Link
                className="text-primary-foreground text-sm sm:text-base"
                to="/forgot-password"
              >
                Forgot Password
              </Link>
            </div>

            <Button
              type="submit"
              variant={"secondary"}
              size={"lg"}
              disabled={isLoading}
              className="!w-full mt-8"
            >
              {isLoading ? (
                <>
                  <TbLoader size={22} className="mr-1.5 animate-spin" /> PLEASE
                  WAIT...
                </>
              ) : (
                "LOG IN ACCOUNT"
              )}
            </Button>
            <p className="text-center text-sm sm:text-base">
              Are you new here?{" "}
              <Link className="text-primary-foreground" to="/sign-up">
                Create Account
              </Link>
            </p>
          </form>
        </div>

        <div className="w-full mt-auto">
          <div className="bg-secondary h-4 rounded-full w-max gap-1 flex items-center p-1">
            <span className="h-2 w-6 rounded-full bg-white" />
            <span className="size-2 rounded-full bg-white" />
            <span className="size-2 rounded-full bg-white" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
