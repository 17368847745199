export const useLocalStorage = () => {
  const setFn = (key: string, value: unknown) => {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {}
  };

  const getFn = (key: string) => {
    try {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) : undefined;
    } catch (error) {}
  };

  const delFn = (key: string) => {
    try {
      localStorage.removeItem(key);
    } catch (error) {}
  };

  return { setFn, getFn, delFn };
};
