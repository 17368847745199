import { FormEvent, useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
import Assets from "src/assets";
import Button from "src/common/components/Button";
// import { AppDispatch } from "src/state/store";
// import {
//   getCurrentUser,
//   updateProfile,
//   uploadProfilePicture,
// } from "../controllers/Profile.Controller";
import { useLocalStorage } from "src/common/controllers/localstorage";
// import { useNavigate } from "react-router-dom";
import { AiOutlineCamera } from "react-icons/ai";
import countryList from "react-select-country-list";
import { apiClient, IMAGE_ENDPOINT, IMAGE_PATH } from "src/lib/utils";
import toast from "react-hot-toast";
import { TbLoader } from "react-icons/tb";
import axios from "axios";

const Profile = () => {
  // const navigate = useNavigate();
  // const dispatch: AppDispatch = useDispatch();

  const [result, setResult] = useState<{ data: any }>({ data: {} });
  // const { error, success, result } = useSelector(
  //   (state: RootState) => state.profile
  // );

  const { getFn } = useLocalStorage();

  const credentials = getFn("credentials");
  const [updating, setUpdating] = useState(false);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [previewImage, setPreviewImage] = useState<string>("");
  const [countries, setCountries] = useState<string[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);
  const [telegramUsername, setTelegramUsername] = useState("");
  const [xProfileLink, setXProfileLink] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [nationality, setNationality] = useState<string>("");

  useEffect(() => {
    // Load country list on component mount

    setCountries(
      countryList()
        .getData()
        .map((country) => country.label)
    );
    setResult({ data: credentials });
  }, []);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setSelectedImage(file);
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  const handleUpload = async () => {
    if (uploading) return;
    if (selectedImage) {
      const formData = new FormData();
      formData.append("image", selectedImage);

      if (credentials?.accessToken) {
        try {
          setUploading(true);
          const response = await axios.post(
            `${IMAGE_ENDPOINT}/upload`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${credentials.accessToken}`,
                "Content-Type": "multipart/form-data",
                "x-environment": process.env.REACT_APP_IMAGE_ENV,
              },
            }
          );

          if (response.data.success) {
            toast.success("Image Uploaded");
            localStorage.setItem(
              "credentials",
              JSON.stringify({
                ...credentials,
                profile_picture: `${response.data.data.file}`,
              })
            );
          } else {
            toast.error(
              response.data.message ?? "OOPPSS!! Something went wrong"
            );
          }
        } catch (error) {
          toast.error("Error uploading profile picture");
        }

        setUploading(false);
      } else {
        console.error("Access token is missing");
      }
    }
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (updating) return;
    function filterEmptyValue(obj: any) {
      return Object.fromEntries(
        Object.entries(obj).filter(
          ([key, value]) =>
            value !== "" && value !== null && value !== undefined
        )
      );
    }
    setUpdating(true);
    try {
      const payload = {
        nationality,
        telegram_user_name: telegramUsername,
        x_profile_link: xProfileLink,
        wallet: walletAddress,
      };

      const processed_payload = filterEmptyValue(payload);

      const response = await apiClient.put("/users", processed_payload, {
        headers: {
          Authorization: `Bearer ${credentials.accessToken}`,
        },
      });

      if (response.data.success) {
        toast.success(response.data.message);
        localStorage.setItem(
          "credentials",
          JSON.stringify({
            ...credentials,
            ...processed_payload,
          })
        );
      } else {
        toast.error(response.data.message ?? "OOPPSS!! Something went wrong");
      }
    } catch (error) {
      toast.error("OOPPSS!! Something went wrong");
    }

    setUpdating(false);
  };

  return (
    <div className="px-2 sm:px-4 lg:px-8 py-6 flex flex-col w-full gap-8">
      <div className="p-4 h-[40px] relative">
        <div className="size-full absolute top-0 left-0 bg-[#ECF9F0] opacity-80">
          <img src={Assets.rough} alt="" className="size-full object-fill" />
        </div>

        <div className="relative flex items-center h-full">
          <h5 className="text-[#28A267]">UPDATING YOUR PROFILE</h5>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <h5>PROFILE PICTURE</h5>

        <div className="flex items-center gap-8">
          <div className="size-[160px] md:size-[200px] rounded-full bg-secondary relative overflow-hidden">
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              hidden
              className="hidden"
              id="profilePictureInput"
            />
            <label
              htmlFor="profilePictureInput"
              className="absolute bottom-0 left-0 h-[48px] w-full bg-black/80 flex items-center justify-center z-1"
            >
              <AiOutlineCamera className="size-6" />
            </label>
            <img
              src={
                previewImage ||
                JSON.parse(localStorage.getItem("credentials")!).profile_picture
                  ? `${IMAGE_ENDPOINT}/${IMAGE_PATH}/${
                      JSON.parse(localStorage.getItem("credentials")!)
                        .profile_picture
                    }`
                  : Assets.dummyAvatar
              }
              onError={(e) => {
                (e.target as HTMLImageElement).onerror = null; // Prevent infinite loop
                (e.target as HTMLImageElement).src = Assets.dummyAvatar;
              }}
              alt={"Profile"}
              className="size-full object-cover rounded-full"
            />
          </div>

          <Button
            onClick={handleUpload}
            disabled={!previewImage || uploading}
            variant={"outline"}
          >
            {uploading ? "UPLOADING..." : "UPLOAD PHOTO"}
          </Button>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="flex flex-col w-full gap-8">
        <div className="flex flex-col gap-4">
          <h5>PERSONAL DETAILS</h5>

          <div className="flex flex-col gap-3 sm:gap-4">
            <div className="flex items-center gap-3 sm:gap-4 flex-col sm:flex-row">
              <div className="flex flex-col w-full gap-1">
                <p className="text-sm">FIRST NAME</p>
                <div className="w-full h-[48px] bg-[#003428] opacity-50 pointer-events-none select-none flex items-center px-5 rounded-md">
                  <p className="text-sm">{result?.data?.firstname || "N/A"}</p>
                </div>
              </div>
              <div className="flex flex-col w-full gap-1">
                <p className="text-sm">LAST NAME</p>
                <div className="w-full h-[48px] bg-[#003428] opacity-50 pointer-events-none select-none flex items-center px-5 rounded-md">
                  <p className="text-sm">{result?.data?.lastname || "N/A"}</p>
                </div>
              </div>
            </div>
            <div className="flex items-center gap-3 sm:gap-4 flex-col sm:flex-row">
              <div className="flex flex-col w-full gap-1">
                <p className="text-sm">USERNAME</p>
                <div className="w-full h-[48px] bg-[#003428] opacity-50 pointer-events-none select-none flex items-center px-5 rounded-md">
                  <p className="text-sm">{result?.data?.username ?? "N/A"}</p>
                </div>
              </div>
              <div className="flex flex-col w-full gap-1">
                <p className="text-sm">EMAIL</p>
                <div className="w-full h-[48px] bg-[#003428] opacity-50 pointer-events-none select-none flex items-center px-5 rounded-md">
                  <p className="text-sm">{result?.data?.email ?? "N/A"}</p>
                </div>
              </div>
            </div>
            <div className="flex items-center gap-3 sm:gap-4 flex-col sm:flex-row">
              <div className="flex flex-col w-full gap-1">
                <p className="text-sm">COUNTRY / REGION OF RESIDENCE</p>
                <select
                  value={
                    nationality ? nationality : result?.data?.nationality ?? ""
                  } // Set value to nationality state
                  onChange={(e) => setNationality(e.target.value)}
                  className="w-full h-[48px] bg-[#003428] flex items-center px-4 rounded-md outline-none focus:outline-none text-sm"
                >
                  <option value="">Select your country</option>
                  {countryList()
                    .getData()
                    .map((country) => (
                      <option key={country.label} value={country.value}>
                        {country.label}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-4">
          <h5>SOCIAL NETWORK DETAILS</h5>

          <div className="flex flex-col gap-3 sm:gap-4">
            <div className="flex items-center gap-3 sm:gap-4 flex-col sm:flex-row">
              <div className="flex flex-col w-full gap-1">
                <p className="text-sm">TELEGRAM USERNAME</p>
                <input
                  type="text"
                  defaultValue={
                    result?.data?.telegram_user_name || telegramUsername
                  }
                  onChange={(e) => setTelegramUsername(e.target.value)}
                  className="w-full h-[48px] bg-[#003428] text-white px-4 rounded-md text-sm outline-none focus:outline-none"
                  placeholder="@john_doe"
                />
              </div>
              <div className="flex flex-col w-full gap-1">
                <p className="text-sm">X PROFILE LINK</p>
                <input
                  type="text"
                  value={result?.data?.x_profile_link || xProfileLink}
                  onChange={(e) => setXProfileLink(e.target.value)}
                  className="w-full h-[48px] bg-[#003428] text-white px-4 rounded-md text-sm outline-none focus:outline-none"
                  placeholder="@john_doe"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <h5>WALLET DETAILS</h5>

          <div className="flex flex-col gap-3 sm:gap-4">
            <div className="flex items-center gap-3 sm:gap-4 flex-col sm:flex-row">
              <div className="flex flex-col w-full gap-1">
                <p className="text-sm">WALLET ADDRESS</p>
                <input
                  type="text"
                  defaultValue={result?.data?.wallet || walletAddress}
                  onChange={(e) => setWalletAddress(e.target.value)}
                  className="w-full h-[48px] bg-[#003428] text-white px-4 rounded-md text-sm outline-none focus:outline-none"
                  placeholder="0x0000...0000"
                />
              </div>
            </div>
          </div>
        </div>

        <Button
          type="submit"
          disabled={updating}
          variant={"secondary"}
          className="mt-6 !w-full !h-14"
        >
          {updating ? (
            <>
              <TbLoader size={22} className="mr-1.5 animate-spin" /> UPDATING...
            </>
          ) : (
            "UPDATE CHANGES"
          )}
        </Button>
      </form>
    </div>
  );
};

export default Profile;
