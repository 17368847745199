import React from "react";
import Assets from "src/assets";

const Footer = () => {
  return (
    <div className="bg-background flex flex-col px-5 md:px-10 mt-6">
      <div className="flex items-start flex-col sm:flex-row justify-between w-full py-10 border-b gap-2">
        <img
          src={Assets.logo}
          alt="logo"
          className="max-w-[250px] sm:max-w-[338px] lg:max-w-[538px] w-full object-contain"
        />

        <p className="sm:max-w-[299px]">
          27Prediction League brings sports enthusiasts together to compete,
          predict, and win, providing opportunities to enhance skills, join
          leagues, and learn from top tipsters.
        </p>
      </div>

      <div className="flex sm:items-center flex-col-reverse sm:flex-row justify-between w-full py-8 gap-2">
        <p>©2024 All rights reserved.</p>

        <div className="flex items-center justify-between sm:justify-start gap-8">
          <a
            href="https://www.instagram.com/27_community"
            target="_blank"
            className="!text-base font-bold"
          >
            INSTAGRAM
          </a>
          <a
            href="https://x.com/S27community"
            target="_blank"
            className="!text-base font-bold"
          >
            X
          </a>
          <a
            href="https://discord.gg/27community"
            target="_blank"
            className="!text-base font-bold"
          >
            DISCORD
          </a>
          <a
            href="https://t.me/+tXIkY-sTMmJhM2M0"
            target="_blank"
            className="!text-base font-bold"
          >
            TELEGRAM
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
